.contentWraper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    &_left{
        width: 45%;
    }
    &_right{
        display: flex;
        flex-direction: column;
        min-height: 60vh;
        justify-content:space-between;
        align-items: center;
        padding-top: 50px;
        max-width: 45%;
    }

}
.metamask{
    &_body{
        display: flex;
        flex-direction: column;
        width: 400px;
        border: 1px solid #1F487C;
        border-radius: 5px;
        margin-bottom: 50px;
    }
    &_metadata{
        padding: 10px 20px;
    }
    &_line{
        display: flex;
        justify-content: space-between;
        span{
            font-weight: 600;
            &:last-child{
                color: #959595;
            }
        }
    }
    &_meta{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #1F487C;
        height: 54px;
        position: relative;
    }
    &_refresh{
        cursor: pointer;
        display: flex;
        position: absolute;
        right: 20px;
        top: 17px;
        svg{
            fill: #fff;
            width: 20px;
            height: 20px;
        }
        &:hover{
            opacity: 0.8;
        }
    }
    &_button{
    background: #1F487C;
    cursor: pointer;
    display: flex;
    height: 54px;
    justify-content: center;
    align-items: center;
    width: 400px;
    gap: 10px;
    color: #fff;
    &:hover{
        background: #365379;
    }
}
}