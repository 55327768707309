.customCheckBox{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    transition: .2s ease;
    &--disabled{
      opacity: 0.2 !important;
      transition: .2s ease;
    }
    &_label{
        line-height: 20px;
        margin-left: 10px;
        cursor: pointer;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        letter-spacing: -0.1px;
        color: #768DB2;

    }
    a{
        color: #0094FF;
        text-decoration: none;
        cursor: pointer;
    }
    label{
        position: relative;
        display: flex;
        flex-direction: row;
        margin-right: 9px;
    }
    input {
        margin: 0;
        opacity: 0;
      }

    span {
        position: absolute;
        top: 0px;
        margin-top: 4px;
        cursor: pointer;
        left: 0px;
        height: 16px;
        width: 16px;
        background-color: #2DAFE7;
        border-radius: 2px;
        border: 1px solid #2DAFE7;
      }

     svg{
        color: #fff;
        display: none;
        filter: unset !important;
      }

     input:checked ~ span {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: #2DAFE7;
        border-radius: 2px;
        opacity: 1;
        border: 1px solid #0094FF;
      }

     input:checked ~ span svg {
        display: block;
      }
}
