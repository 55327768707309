.customRb{
    display: flex;
    flex-direction: column;
    width: 100%;
    &_unit{
        display: flex;
        margin-bottom: 10px;
        cursor: pointer;
    }
    &_label{
        display: flex;
        align-items: center;
        margin-left: 20px;
    }
    &_but{
        display: flex;
        height: 25px;
        width: 25px;
        align-items: center;
        box-shadow:0px 0px 0px 5px #F7F9FB inset;
        &--active{
            background-color: #2DAFE7;
        }
    }
}