$primaryColor: #2DAFE7;
$disabledColor: #dedede;

.ant-btn-primary {
    background-color: $primaryColor !important;
    border: none !important;
    &:disabled{
        background-color: $disabledColor !important;
    }
}

.ant-btn-focused{
    outline: 0 !important;
    outline-offset: 0 !important;
}
