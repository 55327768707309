.inputBox{
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
}
.noteEmailCodeSend{
    font-size: 10px;
}

.noFillpassword{
    -webkit-text-security: disc;
    &:hover{
        -webkit-text-security: none;
    }
}