$primaryColor: #2DAFE7;
$disabledColor: #dedede;

.button{
    background: $primaryColor;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1px;
    padding: 13px 20px;
    transition: .2s;
    text-decoration: none;
    border: none;
    &:hover{
        background: #59beea;
        border:none;
        color: #fff;
        transition: .2s;
    }
    &:disabled{
        background-color: #dedede;
    }
    
}
