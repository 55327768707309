.header{
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    max-width: 1182px;
    padding: 10px 0px;
    &_menu{
        display: flex;
        gap: 30px;
    }
}

.langIcon{
    display: flex;
	cursor: pointer;
	svg {
		height: 25px;
		width: 25px;
	}
}
.accountMenu{
    display: flex;
    flex-direction: column;
    min-width: 200px;
    &_name{
        color: #959595;
        cursor: default !important;
    }
    span{
        padding: 10px 5px;
        cursor: pointer;
    }
}