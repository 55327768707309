.verification{
    &_container{
        display: flex;
        gap: 20px;
    }
    &_selfieInformation{
        display: flex;
        width: 100%;
    }
    &_selfieInformationImg{
        display: flex;
        position: relative;
        padding: 20px;
        background-color: #ddf1fa;
        border: 1px solid #D2F1FF;
        color: #89a9d2;
        border-radius: 10px;
        svg{
            width: 150px;
            height: 150px;
        }
    }
    &_selfieInformationImgDoc{
        position: absolute;
        bottom: -20px;
        right: -30px;
        svg{
            width: 100px;
            height: 50px;
        }
    }
    &_selfieInformationList{
        padding-left: 20px;
    }
    &_selfieInformationFrame{
        display: flex;
        flex-direction: column;
        position: relative;
        margin: auto;
        gap: 20px;
        padding: 30px;
        height: 300px;
        width: 80%;
        background-color: #fafafa;
        border: 3px dotted #bdbdbd;
        align-items: center;
        justify-content: center;
        position: relative;
        &_button{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            gap:20px;
            justify-content: center;
            position: absolute;
        }
        &_delButton{
            position: absolute;
            top:-10px;
            right: -10px;
            background: #2DAFE7;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;
            border-radius: 50px;
            color: #fafafa;
            cursor: pointer;
        }
        img{
            height: 320px;
            width: 320px;
        }
    }
    &_unit{
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 20px;
        padding: 30px;
        height: 300px;
        width: 45%;
        min-width: 400px;
        background-color: #fafafa;
        border: 3px dotted #bdbdbd;
        align-items: center;
        justify-content: center;
        position: relative;
        &_button{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            gap:20px;
            justify-content: center;
            position: absolute;
        }
        &_delButton{
            position: absolute;
            top:-10px;
            right: -10px;
            background: #2DAFE7;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;
            border-radius: 50px;
            color: #fafafa;
            cursor: pointer;
        }
        img{
            height: 320px;
            width: 320px;
        }
    }
    &_unitContainer{
        p{
            font-size: 14px;
            margin-bottom: 10px;
            color: #959595;
        }
    }
}

.list{
    margin-top: 20px;
    li{
        font-size: 16px;
        line-height: 25px;
    }
}
.centredDiv {
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    align-items: center;
    justify-content: center;
    margin: auto;
    p{
        padding-top: 50px;
    }
}